import React from "react";
import {Grid,TextField, Stack} from "@mui/material";


const StatusForm = (newStatusInfo: any, setNewStatusInfo:any) => {
  
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setNewStatusInfo({
      ...newStatusInfo,
      [name]: value,
    });
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log("newStatusInfo", newStatusInfo);
  };
  return (
    <Stack component="form" noValidate spacing={2}>
          <TextField
            id="name-input"
            name="StatusName"
            label="Title"
            type="text"
            value={newStatusInfo.StatusName}
            onChange={handleInputChange}
          />
          <TextField
            id="type-input"
            name="StatusType"
            label="Work Segment"
            type="text"
            value={newStatusInfo.StatusType}
            onChange={handleInputChange}
          />
          <TextField
            id="image-input"
            name="StatusARAssetName"
            label="Image/Video Name"
            type="text"
            value={newStatusInfo.StatusARAssetName}
            onChange={handleInputChange}
          />
          <TextField
            id="description-input"
            name="StatusDescription"
            label="Description"
            type="text"
            value={newStatusInfo.StatusDescription}
            onChange={handleInputChange}
          />
          <TextField
            id="start-time-input"
            name="StatusStartTime"
            type="datetime-local"
            value={newStatusInfo.StatusStartTime}
            onChange={handleInputChange}
          />
          <TextField
            id="end-time-input"
            name="StatusEndTime"
            type="datetime-local"
            value={newStatusInfo.StatusEndTime}
            onChange={handleInputChange}
          />
    </Stack>
  );
};
export default StatusForm;