import { DecorateContext, Decorator, IModelApp } from "@bentley/imodeljs-frontend"; //RenderGraphic, GraphicBranch, GraphicBuilder, GraphicType
// import { Point3d} from "@bentley/geometry-core"; //Transform
// import { ColorDef, LinePixels, TextString, ViewFlagOverrides } from "@bentley/imodeljs-common";

import {UserLocationMarker, UserLocationMarkerSet} from "../markers/UserLocationMarker"
import { LatestUserLocation } from "../../client/UserLocationClient"

export class RealtimeUserLocationsDecorator implements Decorator {
    private _pointMarkerSet = new UserLocationMarkerSet();
    
      /* Remove all existing markers from the "auto" markerset and create new ones for the given points. */
    public setMarkersData(latestUserLocations: Array<LatestUserLocation>): void {
        this._pointMarkerSet.setMarkersData(latestUserLocations);
        // When the markers change we notify the viewmanager to remove the existing decorations
        this._decorationChanged()
    }

    /** Clears all the markers and invalidates the current decorations */
    public clearMarkers() {
        console.log("clearMarkers - userlocation")
        this._pointMarkerSet.markers.clear();
        this._pointMarkerSet = new UserLocationMarkerSet();
        // When the markers change we notify the viewmanager to remove the existing decorations
        this._decorationChanged()
    }
       
    /** When the inputs change we have to throw away the current graphics.  Also tell the
     * viewport that the exiting decoration need to be redrawn.
     */
    private _decorationChanged() {
        const vp = IModelApp.viewManager.selectedView;
        if (undefined !== vp){
        vp.invalidateDecorations();
        }
    }
    /* Implement this method to add Decorations into the supplied DecorateContext. */
    public decorate(context: DecorateContext): void {
        // console.log("RealtimeUserLocationsDecorator decorate")
        if (!this._pointMarkerSet.viewport) {
            this._pointMarkerSet.changeViewport(context.viewport);
            // console.log("changeViewport this._pointMarkerSet")
        }
        
        /* This method is called for every rendering frame.  We will reuse our marker sets since the locations and images
        for the markers don't typically change. */
        if (context.viewport.view.isSpatialView()) {
            this._pointMarkerSet.addDecoration(context);
            // console.log("Add this._pointMarkerSet")
        }
    }
}