import { DecorateContext, Decorator, IModelApp } from "@bentley/imodeljs-frontend";
import { PoILandmarkMarker, PoILandmarkMarkerSet } from "../markers/PoILandmarkMarker";
import { BuildingInfo, Landmark } from "../../client/LandmarksClient";
// import * as data from "../../data/landmarks.json";
// Decorator is a interface that is defined the type and  functionality of the graphic render in the platform

export class PoILandmarkDecorator implements Decorator {
  // private _iModel: IModelConnection; // need to attach to imodel
  private _autoMarkerSet = new PoILandmarkMarkerSet();
  private _manualMarkerSet = new PoILandmarkMarkerSet();

  /* Remove all existing markers from the "auto" markerset and create new ones for the given points. */
  public setMarkersData(buildingInfo: BuildingInfo, onMouseButtonCallback: any): void {
    this._autoMarkerSet.setMarkersData(buildingInfo, onMouseButtonCallback);

    // When the markers change we notify the viewmanager to remove the existing decorations
    this._decorationChanged()
  }

  /* Adds a single new marker to the "manual" markerset */
  public addMarkerPoint(landmark: Landmark, onMouseButtonCallback?: any): void {
    this._manualMarkerSet.markers.add(new PoILandmarkMarker(
      { x: landmark.BentleyLocation[0], y: landmark.BentleyLocation[1], z: 0},    // first be marker 3d location
      landmark,
      onMouseButtonCallback
    ));

    // When the markers change we notify the viewmanager to remove the existing decorations
    this._decorationChanged()
  }

  /** Clears all the markers and invalidates the current decorations */
  public clearMarkers() {
    this._manualMarkerSet.markers.clear();

    // When the markers change we notify the viewmanager to remove the existing decorations
    this._decorationChanged()
  }

  /** When the inputs change we have to throw away the current graphics.  Also tell the
   * viewport that the exiting decoration need to be redrawn.
   */
  private _decorationChanged() {
    const vp = IModelApp.viewManager.selectedView;
    if (undefined !== vp){
      vp.invalidateDecorations();
    }
  }
  /* Implement this method to add Decorations into the supplied DecorateContext. */
  public decorate(context: DecorateContext): void {
    if (!this._autoMarkerSet.viewport) {
      this._autoMarkerSet.changeViewport(context.viewport);
    }

    if (!this._manualMarkerSet.viewport) {
      this._manualMarkerSet.changeViewport(context.viewport);
    }
    /* This method is called for every rendering frame.  We will reuse our marker sets since the locations and images
       for the markers don't typically change. */
    if (context.viewport.view.isSpatialView()) {
      this._autoMarkerSet.addDecoration(context);
      this._manualMarkerSet.addDecoration(context);
    }
  }
}