import { XAndY, XYAndZ } from "@bentley/geometry-core";
import { Marker, BeButton, BeButtonEvent, IModelApp, NotifyMessageDetails, OutputMessagePriority, MarkerSet, Cluster } from "@bentley/imodeljs-frontend";
import { BuildingInfo, Landmark } from "../../client/LandmarksClient";
// import { PopupMenu, PopupMenuEntry } from "../widgets/PopupMenu";


export class PoILandmarkMarker extends Marker {
  private _landmarkId: string;
  public landmark: Landmark;
  public description: string;
  private _onMouseButtonCallback?: any;

  constructor(location: XYAndZ, landmark: Landmark, onMouseButtonCallback?: any) {
    super(location, { x: 25, y: 25});
    this._landmarkId = landmark.ID;
    this.landmark = landmark
    this.description = landmark.CurrentStatus ?? landmark.Description ?? ""
    this._onMouseButtonCallback = onMouseButtonCallback;
    let setImage = false
    if (landmark.CurrentStatus){
      for (var i = 0; i < landmark.Status!.length; i++) {
        if (landmark.Status![i].StatusName == landmark.CurrentStatus){
          let imageName = landmark.Status![i].StatusARAssetName
          if (imageName){
            setImage = true
            console.log("current status image: ", `./images/${imageName.split(".")[0].split(" ").join("%20")}.png`, landmark)
            this.setImageUrl(`./images/${imageName.split(".")[0].split(" ").join("%20")}.png`)
          }
          break;
        }
      }

    }else if (landmark.ImageName && !setImage){
      // this.setImageUrl(`https://d39vcpowlfmemc.cloudfront.net/construction/images/${landmark.ImageName}`)
      this.setImageUrl(`./images/${landmark.ImageName.split(" ").join("%20")}`)
    }else{
      this.setImageUrl(`./images/location-pin.png`); // for file in the data
    }
    this.title = this.setupTooltip(landmark);  //tooltip when pointer is over this marker
    // let scale: Range1dProps = { low: 0.5, high: 1.50 }
    // this.setScaleFactor(scale);
  }

  private setupTooltip(landmark: Landmark) {
    const smartTableDiv = document.createElement("div");
    // smartTableDiv.className = "landmark-table"; // <h3>${this._landmarkId}</h3>
    smartTableDiv.innerHTML = ` 
    <h5>${landmark.Name}</h5>
    ${this.description}
    `;

    return smartTableDiv;
  }

  public onMouseButton(_ev: BeButtonEvent): boolean {
    console.log("click", this)
    // if (!_ev.isDown) return true; // make is show only one notification message, because event test on down and up
    if (BeButton.Data !== _ev.button || !_ev.isDown || !_ev.viewport || !_ev.viewport.view.isSpatialView())
      return true;

    // output a message and alert to user 
    // NotifyMessageDetails - prority how urge, and brief string
    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Element " + this._landmarkId + " was clicked on"));
    
    // zoom to the item location
    IModelApp.viewManager.selectedView!.zoom(this.worldLocation,  0.5, { animateFrustumChange: true });

    if (this._onMouseButtonCallback) {
      console.log("calling the callback", this.landmark)
      this._onMouseButtonCallback(this.landmark);
      return true;
    }
    return true;
  }
}

/** Marker to show as a stand-in for a cluster of overlapping markers. */
class PoILandmarkClusterMarker extends Marker {
  private static _radius = 13;
  private _cluster: any;
  private _onMouseButtonCallback?: any;

  /** Create a new cluster marker */
  constructor(location: XYAndZ, size: XAndY, cluster: Cluster<PoILandmarkMarker>, onMouseButtonCallback?: any) {
    super(location, size);

    this._onMouseButtonCallback = onMouseButtonCallback;
    this._cluster = cluster;

    // The cluster will be drawn as a circle
    // Display the count of markers in this cluster
    this.label = cluster.markers.length.toLocaleString();
    this.labelColor = "black";
    this.labelFont = "bold 14px san-serif";

    // Concatenate the tooltips from the markers to create the tooltip for the cluster
    const maxLen = 10;
    let title = "";
    cluster.markers.forEach((marker, index: number) => {
      if (index < maxLen) {
        if (index === 0)
          title += marker.landmark.Name ?? marker.landmark.ID;
          title += `<br>${marker.description}`;
      }
    });
    if (cluster.markers.length > maxLen)
      title += "<br>...";

    const div = document.createElement("div");
    div.innerHTML = title;
    this.title = div;
  }

  /** This method will be called when the user clicks on a marker */
  public onMouseButton(ev: BeButtonEvent): boolean {
    if (BeButton.Data !== ev.button || !ev.isDown || !ev.viewport || !ev.viewport.view.isSpatialView())
      return true;

    if (this._onMouseButtonCallback)
      this._onMouseButtonCallback(this._cluster.markers[0].landmark);

    return true; // Don't allow clicks to be sent to active tool
  }

  /** Show the cluster as a white circle with a thick outline */
  public drawFunc(ctx: CanvasRenderingContext2D) {
    ctx.beginPath();
    ctx.strokeStyle = "#372528";
    ctx.fillStyle = "white";
    ctx.lineWidth = 5;
    ctx.arc(0, 0, PoILandmarkClusterMarker._radius, 0, Math.PI * 2);
    ctx.fill();
    ctx.stroke();
  }
}

/** A MarkerSet to hold pin locations. This class supplies to `getClusterMarker` method to create SampleClusterMarker. */
export class PoILandmarkMarkerSet extends MarkerSet<PoILandmarkMarker> {
  public minimumClusterSize = 5;
  private _onMouseButtonCallback?: any;

  // This method is called from within the MarkerSet base class based on the proximity of the markers and the minimumClusterSize
  protected getClusterMarker(cluster: Cluster<PoILandmarkMarker>): Marker { return PoILandmarkClusterMarker.makeFrom(cluster.markers[0], cluster, this._onMouseButtonCallback); }

  /** Create a SamplePinMarker for each input point. */
  public setMarkersData(buildingData: BuildingInfo, onMouseButtonCallback?: any): void {
    this.markers.clear();
    this._onMouseButtonCallback = onMouseButtonCallback;

    for (let key in buildingData.poiBuildingLandmarks) {
      let landmark = buildingData.poiBuildingLandmarks[key];
      this.markers.add(new PoILandmarkMarker(
        { x: landmark.BentleyLocation[0], y: landmark.BentleyLocation[1], z: 0},    // first be marker 3d location
        landmark,
        onMouseButtonCallback
      ));
    }
  }
  /** Drop one particular marker from the set. */
  public removeMarker(marker: PoILandmarkMarker) {
    this.markers.delete(marker);
    // When the markers change we notify the viewmanager to remove the existing decorations
    const vp = IModelApp.viewManager.selectedView;
    if (undefined !== vp)
      vp.invalidateDecorations();
  }

  /** Drop all markers from the set */
  public clear() {
    this.markers.clear();
  }
}