import React, { FunctionComponent } from "react";
import { Range1d } from "@bentley/geometry-core";
import { ColorDef, ColorDefProps, Gradient, ThematicGradientColorScheme, ThematicGradientMode, ThematicGradientSettings } from "@bentley/imodeljs-common";

import "../../scss/Legend.scss"

export interface LegendProps {
    numRows: number;
    // colors: Gradient.Symb;
    values: Range1d;
    shape: "square" | "circle";
    title: string | undefined;
    title2: string | undefined;
}

export const Legend: FunctionComponent<LegendProps> = ({numRows = 6, values, shape = "square", title, title2=undefined}) => {
    const rows = [];
    const marginColor: ColorDefProps = ColorDef.computeTbgrFromComponents(0, 0, 255, 255);
    const thematicSettings = ThematicGradientSettings.fromJSON({
      colorScheme: ThematicGradientColorScheme.Custom,
      mode: ThematicGradientMode.Smooth,
      stepCount: 0,
      marginColor,
      customKeys: [
        { value: 0.0, color: marginColor },
        { value: 0.5, color: ColorDef.computeTbgrFromComponents(0, 255, 0, 150) },
        { value: 0.75, color: ColorDef.computeTbgrFromComponents(255, 255, 0, 100) },
        { value: 1.0, color: ColorDef.computeTbgrFromComponents(255, 0, 0, 50) },
      ],
    });
    const colors = Gradient.Symb.createThematic(thematicSettings);

    for (let iRow = 0; iRow < numRows; iRow++) {
        const lowFraction = (iRow - 0.5) / (numRows - 1);
        const midFraction = iRow / (numRows - 1);
        const highFraction = (iRow + 0.5) / (numRows - 1);

        let lowValue = values.fractionToPoint (lowFraction);
        const midValue = values.fractionToPoint (midFraction);
        let highValue = values.fractionToPoint (highFraction);

        if (0 === iRow)
            lowValue = midValue;

        if (numRows - 1 === iRow)
            highValue = midValue;

        // const label = "(" + lowValue.toFixed(2) + "," + highValue.toFixed(2) + "]";
        const label = "(" + lowValue.toFixed(0) + "," + highValue.toFixed(0) + "]";

        const color = colors.mapColor(midFraction).toRgbString();
        rows.push (<LegendRow color={color} label={label} shape={shape}/>)
    }

    return (
        <>
        <div className="legend">
            <div className="legend-title">
                <strong>{title}</strong>
                <br/>
                <strong>{title2}</strong>
            </div>
            <div className="legend-content">
                {rows}
            </div>
        </div>
        </>
    );
};

interface RowProps {
    color: string;
    label: string;
    shape: "square" | "circle";
}

export const LegendRow: FunctionComponent<RowProps> = ({color, label, shape}) => {
    const radius = "circle" === shape ? "50%" : "0%";

    return (
        <>
            <div className="legend-color-shape" style={{backgroundColor: color, borderRadius: radius }}/>
            <span>{label}</span>
        </>
    );
}

