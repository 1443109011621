import "./App.scss";

import { Viewer } from "@bentley/itwin-viewer-react";
import React, { useEffect, useState } from "react";

import AuthorizationClient from "./AuthorizationClient";
import { Header } from "./Header";
import { IModelApp, IModelConnection, ScreenViewport } from "@bentley/imodeljs-frontend";
import { DisplayStyleSettingsProps } from "@bentley/imodeljs-common";
import { Visualization } from "./Visualization"
import { IModelViewportControlOptions } from "@bentley/ui-framework";
import { Point3d } from "@bentley/geometry-core";
// import { ViewerOnly2dApi } from "./ViewerOnly2dApi";
// import { ViewerOnly2dWidgetProvider } from "./ViewerOnly2dWidget";
// import { ItwinViewerUi } from "@bentley/itwin-viewer-react";
// import { default2DSandboxUi } from "@itwinjs-sandbox";
import { PoILandmarksWidgetProvider } from "./providers/PoILandmarksWidgetProvider";
import { HeatmapDecoratorWidgetProvider } from "./providers/HeatmapDecoratorWidgetProvider";

const App: React.FC = () => {
  const [isAuthorized, setIsAuthorized] = useState(
    AuthorizationClient.oidcClient
      ? AuthorizationClient.oidcClient.isAuthorized
      : false
  );
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  // const uiProviders = [new ViewerOnly2dWidgetProvider()];
  const [viewportOptions, setViewportOptions] = useState<IModelViewportControlOptions>();

  // const _oniModelReady = async (iModelConnection: IModelConnection) => {
  //   const result = await ViewerOnly2dApi.getInitial2DModel(iModelConnection);
  //   const viewState = await ViewerOnly2dApi.createDefaultViewFor2dModel(iModelConnection, result);
  //   setViewportOptions({ viewState });
  //   // IModelApp.viewManager.onViewOpen.addOnce(async (vp: ScreenViewport) => {
  //   //   IModelApp.viewManager.addDecorator(new PoILandmarkDecorator(vp)); //add marker to iModel
  //   // })
  // };
  useEffect(() => {
    const initOidc = async () => {
      if (!AuthorizationClient.oidcClient) {
        await AuthorizationClient.initializeOidc();
      }

      try {
        // attempt silent signin
        await AuthorizationClient.signInSilent();
        setIsAuthorized(AuthorizationClient.oidcClient.isAuthorized);
      } catch (error) {
        // swallow the error. User can click the button to sign in
      }
    };
    initOidc().catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (!process.env.IMJS_CONTEXT_ID) {
      throw new Error(
        "Please add a valid context ID in the .env file and restart the application"
      );
    }
    if (!process.env.IMJS_IMODEL_ID) {
      throw new Error(
        "Please add a valid iModel ID in the .env file and restart the application"
      );
    }
  }, []);

  useEffect(() => {
    if (isLoggingIn && isAuthorized) {
      setIsLoggingIn(false);
    }
  }, [isAuthorized, isLoggingIn]);

  const onLoginClick = async () => {
    setIsLoggingIn(true);
    await AuthorizationClient.signIn();
  };

  const onLogoutClick = async () => {
    setIsLoggingIn(false);
    await AuthorizationClient.signOut();
    setIsAuthorized(false);
  };

  const onIModelConnected = (_imodel: IModelConnection) => {
    IModelApp.viewManager.onViewOpen.addOnce(async (vp: ScreenViewport) => {
      const viewStyle: DisplayStyleSettingsProps = {
        viewflags: {
          visEdges: false,
          shadows: true
        }
      }
      vp.overrideDisplayStyle(viewStyle);
      vp.zoom(Point3d.create(85, 8, 0), 0.5, { animateFrustumChange: true });
      Visualization.hideComponents(vp);
      // IModelApp.viewManager.addDecorator(new PoILandmarkDecorator(vp)); //add marker to iModel
    })

  }

  return (
    <div className="viewer-container">
      <Header
        handleLogin={onLoginClick}
        loggedIn={isAuthorized}
        handleLogout={onLogoutClick}
      />
      {isLoggingIn ? (
        <span>"Logging in...."</span>
      ) : (
        isAuthorized && (
          <Viewer
            contextId={process.env.IMJS_CONTEXT_ID ?? ""}
            iModelId={process.env.IMJS_IMODEL_ID ?? ""}
            authConfig={{ oidcClient: AuthorizationClient.oidcClient }}
            theme="light" // "dark"
            onIModelConnected={onIModelConnected} // when have open imodel connection
            viewportOptions={viewportOptions}
            // defaultUiConfig={default2DSandboxUi}
            uiProviders={[new PoILandmarksWidgetProvider(), new HeatmapDecoratorWidgetProvider()]}  // register list of  provider, new RoutePathWidgetProvider()
          />
        )
      )}
    </div>
  );
};

export default App;