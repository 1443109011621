import { IModelApp } from "@bentley/imodeljs-frontend";
import React, { useCallback, useEffect, useState } from "react";
import { HorizontalTabs, Spinner, SpinnerSize } from "@bentley/ui-core";
import { Point3d } from "@bentley/geometry-core";
import { Body, IconButton, Leading, Subheading, Table } from "@itwin/itwinui-react";
import { useActiveViewport } from "@bentley/ui-framework";
import { Button } from "@mui/material";

import { LandmarksClient, BuildingInfo, Landmark } from "../../client/LandmarksClient"
import { Status } from "../../client/LocationStatus"
import { PoILandmarkDecoratorApi } from "../apis/PoILandmarkDecoratorApi"
import { PoILandmarkDecorator } from "../decorators/PoILandmarkDecorator"
import StatusForm from "../ui/NewStatusForm"
import {LegendRow} from "../ui/Legend"

import "../../scss/Issues.scss";
const GifPlayer = require('react-gif-player');

export function PoILandmarksListWidget() {
  const viewport = useActiveViewport();
  const [numberPoI, setNumberPoI] = useState<number>(0);
  /** The active tab when the issue is being shown, -1 for none */
  const [activeTab, setActiveTab] = useState<number>(0);
  const [addNewStatus, setAddNewStatus] = useState<number>(0);

  const [currentBuildingInfo, setCurrentBuildingInfo] = useState<BuildingInfo>();
  const [currentLandmark, setCurrentLandmark] = useState<Landmark>();
  const [landmarkDecorator, setLandmarkDecorator] = React.useState<PoILandmarkDecorator>(() => {
    return PoILandmarkDecoratorApi.setupDecorator();
  });

  const [newStatusInfo, setNewStatusInfo] = useState<Status>({
    StatusName: "",
    StatusStartTime: "",
  });
  const statusColorLegend = ["#ff6666", "#ffbf80", "#6FC276"]

  /** When iModel is loaded, get landmark details */
  useEffect(() => {
    (async () => {
      console.log("setCurrentBuildingInfo")
      const builindInfo = await LandmarksClient.getBuildingInfo();
      if (builindInfo){
        setCurrentBuildingInfo(builindInfo);
        setNumberPoI(Object.keys(builindInfo.poiBuildingLandmarks).length)
      }
    })()
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [viewport]);

    
  /** Initialize Landmark Decorator */
  useEffect(() => {
    PoILandmarkDecoratorApi.enableDecorations(landmarkDecorator);
    return () => {
      PoILandmarkDecoratorApi.disableDecorations(landmarkDecorator);
    };
  }, [landmarkDecorator]);


  /** Create the issue marker icon, then add the pin at the issue location */
  useEffect(() => {
    /** Clear the current points */
    PoILandmarkDecoratorApi.clearDecoratorPoints(landmarkDecorator);
    if (currentBuildingInfo){
      for (let key in currentBuildingInfo.poiBuildingLandmarks) {
        let landmark = currentBuildingInfo.poiBuildingLandmarks[key];
        PoILandmarkDecoratorApi.addDecoratorPoint(landmarkDecorator, landmark, (landmark: Landmark)=>{
          console.log("callback", landmark)
          setCurrentLandmark(landmark);
          setActiveTab(0);
        }) 
      }
    }
  }, [currentBuildingInfo, landmarkDecorator]);

  const zoomIn = useCallback(async (landmark: Landmark) => {
    IModelApp.viewManager.selectedView!.zoom(Point3d.create(landmark.BentleyLocation[0], landmark.BentleyLocation[1], 0),  1.0, { animateFrustumChange: true })
  }, []);

  const landmarkSummaryContent = () => {
    const columns = [{
      Header: "Table",
      columns: [
        { id: "properties", Header: "Properties", accessor: "prop" },
        { id: "value", Header: "Value", accessor: "val" },
      ],
    }];
    let data = [
      // { prop: "Id", val: currentLandmark?.ID },
      { prop: "Name", val: currentLandmark?.Name },
      // { prop: "Download Speed", val: currentLandmark?.DownloadSpeed },
      // { prop: "World Location", val: currentLandmark?.WorldLocation!.join(",") }
    ];

    if (currentLandmark?.CurrentStatus){
      data.push({ prop: "Current Status", val: currentLandmark?.CurrentStatus },)
    }
    if (currentLandmark?.Description){
      data.push({ prop: "Description", val: currentLandmark?.Description },)
    }
    if (currentLandmark?.ImageName){
      data.push({ prop: "Image Name", val: currentLandmark?.ImageName },)
    }
    if (currentLandmark?.BarrierType){
      data.push({ prop: "BarrierType", val: currentLandmark?.BarrierType })
    }
    return (<Table className={"table"} columns={columns} data={data} emptyTableContent='No data.'></Table>);
  };

  function roundToTwo(num: number) {    
      return Math.round((num + Number.EPSILON) * 100) / 100
  }

  function handleNewStatus(){
    if (addNewStatus == 0){
      setAddNewStatus(1)
    } else{
      if (newStatusInfo.StatusName === "" || newStatusInfo.StatusStartTime == ""){
        alert("Please fill in the title and the start time")
      }else{
        newStatusInfo.StatusStartTime = convertTimeString(newStatusInfo.StatusStartTime)
        if (newStatusInfo.StatusEndTime) {
          newStatusInfo.StatusEndTime = convertTimeString(newStatusInfo.StatusEndTime)
        }
        console.log("handleNewStatus - after process status", newStatusInfo);

        LandmarksClient.addLandmarkStatus(currentLandmark!, newStatusInfo).then((status) => {
          console.log("handleNewStatus - status", status);
          if (status){
            if (currentLandmark!.Status){
              currentLandmark!.Status.push(status!) // = [status!].concat(currentLandmark!.Status)
              currentLandmark!.Status.sort((a,b) => b.StatusStartTime.localeCompare(a.StatusStartTime))
            }else{
              currentLandmark!.Status = [status!]
            }
            
            // if (0 < status!.ProgressPercentage! && status!.ProgressPercentage! < 100){
            //   currentLandmark!.CurrentStatus = status.StatusName

            //   var building = currentBuildingInfo
            //   building!.poiBuildingLandmarks[currentLandmark!.ID] = currentLandmark!
            //   // setCurrentLandmark(currentLandmark)
            //   console.log("update current status", currentLandmark)
              
            // }else{
            //   // setCurrentLandmark(currentLandmark)
            //   console.log("not update current status", currentLandmark)
            //   currentBuildingInfo!.poiBuildingLandmarks[currentLandmark!.ID] = currentLandmark!
            // }
            
            LandmarksClient.getBuildingInfo().then((builindInfo) =>{
              if (builindInfo){
                setCurrentBuildingInfo(builindInfo);
              }
            });
            setActiveTab(0)
            setActiveTab(1)
          }
          
        })
        setAddNewStatus(0)
      }
    }
  }

  const convertTimeString = (timeString: string) => {
    let time = new Date(timeString)
    let split = timeString.split('T');
    return `${split[0]} ${split[1]}:00`
  }

  const allLandmarkStatusContent = () => {
    if (currentLandmark?.Status){
      return (
        <div>
          { currentLandmark?.Status.map(status => {return (landmarkStatusContent(status))}) }
        </div>
    )} else{
      return (<Body style={{ color: "#000" }}>No Status.</Body>);
    } 
  };

  const landmarkStatusContent = (status: Status) =>{
    // console.log("landmarkStatusContent", status)
    const startTime = status.StatusStartTime.substring(0,16)
    const endTime = status.StatusEndTime ?  status.StatusEndTime!.substring(0,16): "now" 
    let statusColor = statusColorLegend[1]
    if (status.ProgressPercentage == 100) {
      statusColor = statusColorLegend[2]
    }else if (status.ProgressPercentage == 0) {
      statusColor = statusColorLegend[0]
    }
    return (
      <div className="issue" style={{backgroundColor: statusColor}}>
        <div className="issue-preview">
          {getStatusImage(status.StatusARAssetName)}
        </div>
        <div className="issue-info">
            <Leading className={"issue-title"}>{`${status.StatusName}`}</Leading>
            <div className="issue-subtitle">
              <span className={"assignee-display-name"}>{`${status.StatusType} | ${startTime} - ${endTime}`}</span>
              {status.ProgressPercentage &&
                <span className={"assignee-display-name"}>{`Progress: ${status.ProgressPercentage? `${roundToTwo(status.ProgressPercentage)}%` : "N/A"}`}</span>
              }
              <span className={"assignee-display-name"}>{`Image: ${status.StatusARAssetName ? status.StatusARAssetName:  "N/A"}`}</span>
              <span className={"assignee-display-name"}>{`Description: ${status.StatusDescription}`}</span>
            </div>
          </div>
      </div>
    )
  }
  const getStatusImage = (imageName: string | undefined) =>{
    // console.log("getStatusImage",imageName)
    if (imageName){
      if (imageName!.includes("mp4")) {
        const giffile = getLandmarkImageLocation(imageName, false)
        const imagefile = getLandmarkImageLocation(imageName, true)
        // console.log("gif files:", giffile, imagefile)
        return (<GifPlayer
          gif={giffile}
          still={imagefile}
          style={{width: 80, height: 120 }}
          // pauseRef={pause => this.pauseGif = pause}
        />)
      }else{
        return (<div className="thumbnail" style={{ backgroundImage: `url(${getLandmarkImageLocation(imageName)})`}}/>)
      }
    }else{
      return (<div className="thumbnail" style={{ backgroundImage: `url(/images/location-pin.png)`}}/>)
    }
  }

  const getLandmarkImageLocation = (imageName: string | undefined, isPNG: Boolean = true) =>{
    if (imageName){
      // console.log(`original image name - ${imageName}`)
      imageName = imageName.replace("mp4", isPNG ? "png" : "gif").split(" ").join("%20");
      // console.log(`replace image name - ${imageName}`)
      
      if (false) {
        console.log(`get image from cloud - ${imageName}`)
        return `https://d39vcpowlfmemc.cloudfront.net/ix_construction/images/${imageName}`
      }
      // console.log(`get image from local - ${imageName}`)
      return `./images/${imageName}`
    }
    return "/images/location-pin.png"
  }

  return (
    <>
    <div>
    {/** Only display header when issue isn't selected */}
    {!currentLandmark &&
      <div className="issue-list-header">
        <Subheading style={{ margin: "0", padding: "8px 5px", color: "#000" }}>{`(${numberPoI} PoI Locations)`}</Subheading>
      </div>
    }
    {/** When the issues haven't loaded yet, display spinner */}
    { currentBuildingInfo == null &&
      <Spinner size={SpinnerSize.Medium} />
    }
    {/** When there are no issues retrieved from filter. */}
    { currentBuildingInfo != null && 
      numberPoI === 0 && 
      <span style={{ color: "#000", padding: "4px" }}>No Content.</span>
    }
    {/** When the landmarks are loaded, display them in a list */}
    {!currentLandmark && currentBuildingInfo && numberPoI && numberPoI > 0 &&
      <div>
        {
          Object.keys(currentBuildingInfo.poiBuildingLandmarks).map((key: string) => {
            const landmark = currentBuildingInfo.poiBuildingLandmarks[key]
            
            return (
              <div key={landmark.ID} className="issue">
                <div className="issue-preview" style={{ height: 60 }}>
                    <div className="thumbnail" style={{ backgroundImage: `url(${getLandmarkImageLocation(landmark.ImageName)})` }} onClick={async () => zoomIn(landmark)}>
                      <span className="open icon icon-zoom" title={"Locate & Zoom"} />
                    </div>
                </div>
                <div className="issue-info" onClick={() => { setCurrentLandmark(landmark); setActiveTab(0); }}>
                    <Leading className={"issue-title"}>{`${landmark.Name}`}</Leading>
                    <div className="issue-subtitle">
                      {/* <span className={"assignee-display-name"}>{landmark.Description}</span> */}
                      <div className={"created-date"}>
                        <span>{landmark.CurrentStatus ?? landmark.Description}</span>
                      </div>
                    </div>
                  </div>
              </div>
            )
          })
        }
      </div>
    }
    {/** When an issue is selected from the initial list, show the tab interface */}
    {currentLandmark &&
          <div className={"issue-details"}>
            <div className={"header"}>
              <IconButton styleType='borderless' size='small' className="back-button" onClick={() => { setCurrentLandmark(undefined); }}>
                <span className="icon icon-chevron-left" style={{ color: "black" }}></span>
              </IconButton>
              <Subheading style={{ margin: "0", padding: "8px 5px", color: "#000" }}>{`${currentLandmark.Name}`}</Subheading>
            </div>

            <HorizontalTabs type='default' labels={["Summary", "Status"]} activeIndex={activeTab} onActivateTab={(index) => setActiveTab(index)} />
            <div className={"issue-tab-content"}>
              {activeTab === 0 &&
                <div className={"issue-summary"}>
                  {landmarkSummaryContent()}
                </div>
              }
              {activeTab === 1 &&
                <div className={"issue-attachments"}>
                  <div className={"sample-options-3col"}>
                    <div> <LegendRow color={statusColorLegend[2]} label={"\tCompleted"} shape={"square"}/></div>
                    <div> <LegendRow color={statusColorLegend[1]} label={"\tIn Progress"} shape={"square"}/></div>
                    <div> <LegendRow color={statusColorLegend[0]} label={"\tFuture Work"} shape={"square"}/></div>
                  </div>

                  <div className={"sample-options-2col"}>
                  <Button variant="contained" color="primary" onClick={handleNewStatus}> 
                  Add New Status {/* {addNewStatus == 0 ? "Add New Status": "Cancel"} */}
                  </Button> 
                  {addNewStatus === 1 &&
                  <Button variant="contained" color="warning" onClick={() => {setAddNewStatus(0)}}> Cancel</Button> 
                  }
                  </div>
                  
                  {addNewStatus === 1 &&
                    <div>
                      <br/>
                      {StatusForm(newStatusInfo, setNewStatusInfo)}
                      <br/>
                    </div>
                  }
                  {allLandmarkStatusContent()}
                  {/* {currentLandmark?.Status &&
                  <div>
                    { currentLandmark?.Status.map(status => {return (landmarkStatusContent(status))}) }
                  </div>
                  }
                  {!currentLandmark?.Status &&
                    <Body style={{ color: "#000" }}>No Status.</Body>
                  } */}
                </div>
              }
            </div>
          </div>
        }
    </div>
    </>

  )
}
