import { XAndY, XYAndZ, Point3d } from "@bentley/geometry-core";
import { Marker, BeButton, BeButtonEvent, IModelApp, MarkerSet, Cluster } from "@bentley/imodeljs-frontend";
import { LatestUserLocation } from "../../client/UserLocationClient"


export class UserLocationMarker extends Marker {
  constructor(location: Point3d, latestUserLocation: LatestUserLocation) {
      console.log("UserLocationMarker", latestUserLocation)
    super(location, { x: 25, y: 25});
     // The title will be shown as a tooltip when the user interacts with the marker
     this.title = "Edion Mecaj, CM"//latestUserLocation.deviceId;
     this.setImageUrl(`./images/manager.png`)
  }
  public updateLocation(location: Point3d){
    this.worldLocation = location
  }

  public onMouseButton(_ev: BeButtonEvent): boolean {
    console.log("click", this)
    // if (!_ev.isDown) return true; // make is show only one notification message, because event test on down and up
    if (BeButton.Data !== _ev.button || !_ev.isDown || !_ev.viewport || !_ev.viewport.view.isSpatialView())
      return true;

    // output a message and alert to user 
    // NotifyMessageDetails - prority how urge, and brief string
    // IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Element " + this._landmarkId + " was clicked on"));
    
    // zoom to the item location
    // IModelApp.viewManager.selectedView!.zoom(this.worldLocation,  0.5, { animateFrustumChange: true });

    return true;
  }
}

/** Marker to show as a stand-in for a cluster of overlapping markers. */
class UserLocationClusterMarker extends Marker {
  private static _radius = 13;
  private _cluster: any;
  private _onMouseButtonCallback?: any;

  /** Create a new cluster marker */
  constructor(location: XYAndZ, size: XAndY, cluster: Cluster<UserLocationMarker>, onMouseButtonCallback?: any) {
    super(location, size);

    this._onMouseButtonCallback = onMouseButtonCallback;
    this._cluster = cluster;

    // The cluster will be drawn as a circle
    // Display the count of markers in this cluster
    this.label = cluster.markers.length.toLocaleString();
    this.labelColor = "black";
    this.labelFont = "bold 14px san-serif";
  }

  /** This method will be called when the user clicks on a marker */
  public onMouseButton(ev: BeButtonEvent): boolean {
    if (BeButton.Data !== ev.button || !ev.isDown || !ev.viewport || !ev.viewport.view.isSpatialView())
      return true;

    if (this._onMouseButtonCallback)
      this._onMouseButtonCallback(this._cluster.markers[0].landmark);

    return true; // Don't allow clicks to be sent to active tool
  }

  /** Show the cluster as a white circle with a thick outline */
  public drawFunc(ctx: CanvasRenderingContext2D) {
    ctx.beginPath();
    ctx.strokeStyle = "#372528";
    ctx.fillStyle = "white";
    ctx.lineWidth = 5;
    ctx.arc(0, 0, UserLocationClusterMarker._radius, 0, Math.PI * 2);
    ctx.fill();
    ctx.stroke();
  }
}

/** A MarkerSet to hold pin locations. This class supplies to `getClusterMarker` method to create SampleClusterMarker. */
export class UserLocationMarkerSet extends MarkerSet<UserLocationMarker> {
  public minimumClusterSize = 5;
  private _onMouseButtonCallback?: any;

  // This method is called from within the MarkerSet base class based on the proximity of the markers and the minimumClusterSize
  protected getClusterMarker(cluster: Cluster<UserLocationMarker>): Marker { return UserLocationClusterMarker.makeFrom(cluster.markers[0], cluster, this._onMouseButtonCallback); }

    /** Create a SamplePinMarker for each input point. */
    public setMarkersData(latestUserLocations: Array<LatestUserLocation>): void {
        this.markers.clear();
        latestUserLocations.forEach((latestUserLocation: LatestUserLocation) => {
            // console.log("Add PointMarker", userLocation)
            this.markers.add(new UserLocationMarker(
                Point3d.create(latestUserLocation.bentleyLocation![0], latestUserLocation.bentleyLocation![1], 0),
                latestUserLocation
            ));
        })
        }
  /** Drop one particular marker from the set. */
  public removeMarker(marker: UserLocationMarker) {
    this.markers.delete(marker);
    // When the markers change we notify the viewmanager to remove the existing decorations
    const vp = IModelApp.viewManager.selectedView;
    if (undefined !== vp)
      vp.invalidateDecorations();
  }

  /** Drop all markers from the set */
  public clear() {
    this.markers.clear();
  }
}