export interface UserLocationAgg{
    maxDeviceIDCount: number;
    maxOccurrenceSum: number;
    minTime: string;
    maxTime: string;
    userLocations: Array<UserLocation>;
    // BentleyLocations: {[key: string]: Array<number>};
}

export interface UserLocation{
    landmarkId: string;
    deviceIDCount: number;
    occurrenceSum: number;
    time: string;
}

export interface LatestUserLocation{
    DeviceId: string;
    BuildingName: string;
    FloorNumber: string;
    Time: string;
    Coordinates: Coordinates;
    bentleyLocation?: Array<number>;
}

interface Coordinates{
    WorldX: number;
    WorldY: number;
    Latitude: number;
    Longitude: number;
}


export class UserLocationClient {
    private static bentleyRotationMatrix: Array<Array<number>> = [[ 1.91089101e-02, 1.01060425],[ 9.90677539e-01, -7.92506864e-04]];
    private static bentleyTranslateMatrix: Array<number> = [42.67482251, -8.47041822];
    
    public static async getUserLocationAgg(): Promise<UserLocationAgg | undefined>  {
        // const request = "http://127.0.0.1:5000/api/v2/bentley/user_locations/hour/ix_construction/dc9a6fad-6d0f-4d08-a14d-3867f1f7634a"
        const request = "https://dev-ios-navigation-core-api.nearabl.com/api/v2/bentley/user_locations/hour/ix_construction/dc9a6fad-6d0f-4d08-a14d-3867f1f7634a"
        const response = await fetch(request, {
            method: 'GET', 
            mode: 'cors',  
            headers: {
                'Accept': 'application/json',
                // 'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true'
              }
            });
        console.log("userLocationAgg",response)
        let userLocationAgg: UserLocationAgg = await response.json(); 
        console.log("userLocationAgg",userLocationAgg)
        return userLocationAgg;
    }

    public static async getLatestUserLocation(deviceID: string): Promise<LatestUserLocation| undefined> {
        const request = `https://dev-analytics-api.nearabl.com/user/location/latest/${deviceID}`
        // console.log("url",request)
        const response = await fetch(request, {
            method: 'GET', 
            mode: 'cors',  
            headers: {
                // 'Accept': 'application/json',
                // 'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true'
              }
            });
        // console.log("latestUserLocation",response)
        let latestUserLocation = await response.json(); 
        latestUserLocation.bentleyLocation = this.getBentleyLocation(latestUserLocation)
        console.log("latestUserLocation after transformation:", latestUserLocation)
        return latestUserLocation;
    }

    public static getBentleyLocation(latestUserLocation: LatestUserLocation): Array<number>{
        console.log("getBentleyLocation latestUserLocation", latestUserLocation)
        const BentleyLocationX = this.bentleyRotationMatrix[0][0]*latestUserLocation.Coordinates.WorldX + this.bentleyRotationMatrix[0][1]*latestUserLocation.Coordinates.WorldY + this.bentleyTranslateMatrix[0]
        const BentleyLocationY = this.bentleyRotationMatrix[1][0]*latestUserLocation.Coordinates.WorldX + this.bentleyRotationMatrix[1][1]*latestUserLocation.Coordinates.WorldY + this.bentleyTranslateMatrix[1]
        return [BentleyLocationX,BentleyLocationY]
    }
}