import { Geometry, Point3d, Range3d } from "@bentley/geometry-core";

/**
 * Context to build a grid with
 * * XY Coordinates are in a grid within specified range.
 * * Z coordinates are initially constant
 * * z values are modified by later directives.
 */
 export class SampleGridBuilder {
    private _range: Range3d;
    private _gridSize: number;
    public data: number[][];
  
    /** Build a sampled grid with
     * * grid that covers the range of the given points.
     * * is a square with side length 2^N
     * * value at grid point is the max over value of all points for which this is the closest grid point.
     */
    public static build(gridSize: number, spreadFactor: number, range: Range3d, points: Point3d[]): number[][] {
      const baseRadius: number = gridSize * (spreadFactor / 100);
  
      const grid = new SampleGridBuilder(range, gridSize);
      for (const point of points) {
        const i = grid.closestXIndex(point.x);
        const j = grid.closestYIndex(point.y);
        const r = point.z * baseRadius;
        grid.setGridZToCubicMax(i, j, point.z, r);
      }
      return grid.data;
    }
  
    public constructor(range: Range3d, gridSize: number) {
      this._gridSize = gridSize;
      this._range = range.clone();
  
      this.data = [];
      for (let j = 0; j <= this._gridSize; j++) {
        const row: number[] = [];
        for (let i = 0; i <= this._gridSize; i++) {
          row.push(0.0);
        }
        this.data.push(row);
      }
    }
  
    /** Return the closest x index for given x value */
    public closestXIndex(x: number): number {
      return this.closestGridIndex(x, this._range.low.x, this._range.high.x);
    }
  
    /** Return the closest y index for given y value */
    public closestYIndex(y: number): number {
      return this.closestGridIndex(y, this._range.low.y, this._range.high.y);
    }
  
    public closestGridIndex(q: number, q0: number, q1: number) {
      if (q < q0)
        return 0;
      if (q > q1)
        return this._gridSize;
      return Math.floor(0.5 + this._gridSize * (q - q0) / (q1 - q0));
    }
  
    /**
     * Set grid point z to larger of current and zNew
     * @param i x direction index
     * @param j y direction index
     * @param zNew
     */
    public setGridZToMax(i: number, j: number, zNew: number) {
      if (i >= 0 && i <= this._gridSize && j >= 0 && j <= this._gridSize) {
        const zOld = this.data[j][i];
        this.data[j][i] = Math.max(zOld, zNew);
      }
    }
  
    /**
     * * Apply zNew to grid point i,j
     * * for grid points within baseRadius, apply cubic (bell-like) falloff
     * @param i
     * @param j
     * @param zNew
     * @param baseRadius radius of base circle
     */
    public setGridZToCubicMax(iMid: number, jMid: number, zNew: number, baseRadius: number) {
      const n = Math.ceil(baseRadius);
      this.setGridZToMax(iMid, jMid, zNew);
      let u, v, f;
      if (n > 1) {
        for (let j = -n; j <= n; j++) {
          for (let i = -n; i <= n; i++) {
            const r = Geometry.hypotenuseXY(i, j);
            if (r < baseRadius) {
              u = r / baseRadius;
              v = 1.0 - u;
              // general cubic bezier on 4 control values a0,a1,a2,a3 is
              // v^3 a0 + 3 v^2 u a1 + 3 v u^2 a2 + u^3 a3
              // here a0 = a1 = 1, a2 = a3 = 0
              f = v * v * (v + 3 * u);
              this.setGridZToMax(iMid + i, jMid + j, zNew * f);
            }
          }
        }
      }
    }
  }
  