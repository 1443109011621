import React from "react";
import { AbstractWidgetProps, StagePanelLocation, StagePanelSection, UiItemsProvider, WidgetState } from "@bentley/ui-abstract";
import {HeatmapDecoratorWidget} from "../components/widgets/HeatmapWidget"

export class HeatmapDecoratorWidgetProvider implements UiItemsProvider {
    public readonly id: string = "HeatmapDecoratorWidgetProvider";
  
    public provideWidgets(_stageId: string, _stageUsage: string, location: StagePanelLocation, _section?: StagePanelSection): ReadonlyArray<AbstractWidgetProps> {
      const widgets: AbstractWidgetProps[] = [];
      if (location === StagePanelLocation.Right) {
        widgets.push(
          {
            id: "HeatmapDecoratorWidget",
            label: "Traffic Heatmap",
            defaultState: WidgetState.Floating,
            // eslint-disable-next-line react/display-name
            getWidgetContent: () => <HeatmapDecoratorWidget />,
          }
        );
      }
      return widgets;
    }
  }