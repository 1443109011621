export interface UserPathAgg{
    maxDeviceIDCount: number;
    maxOccurrenceSum: number;
    minTime: string;
    maxTime: string;
    userPath: Array<UserPath>;
}

export interface UserPath{
    landmarkIds: string;
    deviceIDCount: number;
    occurrenceSum: number;
    time: string;
}

export class UserPathClient {
    public static async getUserPathAgg(): Promise<UserPathAgg | undefined>  {
        // const request = "http://127.0.0.1:5000/api/v2/bentley/user_paths/hour/ix_construction/dc9a6fad-6d0f-4d08-a14d-3867f1f7634a"
        const request = "https://dev-ios-navigation-core-api.nearabl.com/api/v2/bentley/user_paths/hour/ix_construction/dc9a6fad-6d0f-4d08-a14d-3867f1f7634a"
        const response = await fetch(request, {
            method: 'GET', 
            mode: 'cors',  
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true'
              }
            });
        console.log("UserPathAgg",response)
        let userPathAgg: UserPathAgg = await response.json(); 
        console.log("UserPathAgg",userPathAgg)
        return userPathAgg;
    }
}