import { IModelConnection, ScreenViewport } from "@bentley/imodeljs-frontend";

export class Visualization {

  //optional toggle parameter
  public static hideComponents = async (vp: ScreenViewport, toggle?: boolean) => {

    const categoryIds = await Visualization.getCategoryIds(vp.iModel);

    if (toggle) {
      vp.changeCategoryDisplay(categoryIds, toggle);
    } else {
      vp.changeCategoryDisplay(categoryIds, false);
    }
  }
  private static getCategoryIds = async (iModel: IModelConnection) => {
    const categoriesToHide = [
      "'0-4'",
      "'A-ANNO-NOTE'",
      "'A-ANNO-NOTE-1'",
      "'A-ANNO-NOTE-2'",
      "'A-ANNO-NOTE-3'",
      "'A-ANNO-TEXT-1'",
      "'A-AREA-IDEN'",
      "'A-DETL-1'",
      "'A-DETL-3'",
      "'A-DETL-GENF'",
      "'A-DETL-MBND-3'",
      "'A-WALL-PATT-1'",
      "'E-LITE-EQPM'",
      "'E-LITE-EQPM-1'",
      "'M-ANNO-TEXT'",
      "'M-ANNO-TEXT-1'",
      "'M-EQPT-2'",
      "'M-HVAC-CDFF-1'",
      "'M-HVAC-DUCT'",
      "'M-HVAC-DUCT-2'",
      "'M-HVAC-DUCT-3'",
      "'M-HVAC-DUCT-4'",
      "'M-PIPE'", // the green pipe line
      "'M-PIPE-2'", // the green pipe dot
      "'S-BEAM-1'",
      "'S-GRID'",
      "'S-GRID-1'", // red marks in left
      "'S-GRID-2'", // red marks in left
      "'S-GRID-3'", // red marks in left
      "'S-GRID-4'", // red marks in left
      "'S-BEAM-1'", // gray marks in left
      "'A-FLOR-CASE'", 
      "'A-CLNG-GRID-2'", // blue grid in left 
      // "'A-DETL-GENF-1'", // blue wall in left
      "'A-DETL-GENF-2'", // green in left
      "'A-DETL-GENF-3'", // green in left
      // "'A-GENM-2'", // purple  in left
      "'A-GENM-3'", // light blue  in left

    ]

    const query = `SELECT ECInstanceId FROM Bis.Category 
        WHERE CodeValue IN (${categoriesToHide.toString()})`;
    
    const result = iModel.query(query);
    const categoryIds = [];

    for await (const row of result)
        categoryIds.push(row.id);
    console.log(categoryIds)
    return categoryIds;
  }
}