import { Arc3d, CurveChainWithDistanceIndex, GeometryQuery, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, LineString3d, Loop, Path, Point3d, Transform } from "@bentley/geometry-core";
import { DecorateContext, Decorator, GraphicBranch, GraphicBuilder, GraphicType, IModelApp, RenderGraphic } from "@bentley/imodeljs-frontend";
import { ColorDef, LinePixels, ViewFlagOverrides } from "@bentley/imodeljs-common";
// import { dispose } from "@bentley/bentleyjs-core";

// Since all geometry is rendered concurrently, when adding geometry, we attach their desired attributes to them in an object
interface GeometryLine {
  geometry: GeometryQuery;
  color: ColorDef;
  fill: boolean;
  fillColor: ColorDef;
  lineThickness: number;
  edges: boolean;
  linePixels: LinePixels;
}

export class HeatmapLineDecorator implements Decorator {
  private _graphics?: RenderGraphic; // Cached graphics that can be reused from one frame to another.

  private shapes: GeometryLine[] = [];

  private fill: boolean = false;
  private lineThickness: number = 5;
  private edges: boolean = true;
  private linePixels = LinePixels.Solid;

  // private color: ColorDef = ColorDef.black; 
  // private fillColor: ColorDef = ColorDef.white;

  public addGeometry(geometry: GeometryQuery, color: ColorDef, fillColor: ColorDef) {
    const styledGeometry: GeometryLine = ({
      geometry,
      color: color,
      fill: this.fill,
      fillColor: fillColor,
      lineThickness: this.lineThickness,
      edges: this.edges,
      linePixels: this.linePixels,
    });
    this.shapes.push(styledGeometry);
  }

  public clearGeometry() {
    this.shapes = [];
    this._graphics = undefined;
    IModelApp.viewManager.invalidateDecorationsAllViews();
  }

  // Iterate through the geometry and point lists, extracting each geometry and point, along with their styles
  // Adding them to the graphic builder which then creates new graphics
  public createGraphics(context: DecorateContext): RenderGraphic | undefined {
    // Specifying an Id for the graphics tells the display system that all of the geometry belongs to the same entity, so that it knows to make sure the edges draw on top of the surfaces.
    const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, context.viewport.iModel.transientIds.next);
    builder.wantNormals = true;
    // this.points.forEach((styledPoint) => {
    //   builder.setSymbology(styledPoint.color, styledPoint.fill ? styledPoint.color : ColorDef.white, styledPoint.lineThickness);
    //   const point = styledPoint.point;
    //   const circle = Arc3d.createXY(point, 1);
    //   builder.addArc(circle, false, styledPoint.fill);
    // });
    this.shapes.forEach((styledGeometry) => {
      const geometry = styledGeometry.geometry;
      builder.setSymbology(styledGeometry.color, styledGeometry.fill ? styledGeometry.fillColor : styledGeometry.color, styledGeometry.lineThickness, styledGeometry.linePixels);
      this.createGraphicsForGeometry(geometry, styledGeometry.edges, builder);
    });
    const graphic = builder.finish();
    return graphic;
  }

  private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
    if (geometry instanceof LineString3d) {
      builder.addLineString(geometry.points);
    } else if (geometry instanceof Loop) {
      builder.addLoop(geometry);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each loop edge/arc
        builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const curves = geometry.children;
        curves.forEach((value) => {
          if (value instanceof LineString3d) {
            let edges = value.points;
            const endPoint = value.pointAt(0);
            if (endPoint) {
              edges = edges.concat([endPoint]);
            }
            builder.addLineString(edges);
          } else if (value instanceof Arc3d) {
            builder.addArc(value, false, false);
          }
        });
      }
    } else if (geometry instanceof Path) {
      builder.addPath(geometry);
    } else if (geometry instanceof IndexedPolyface) {
      builder.addPolyface(geometry, false);
      if (wantEdges) {
        // Since decorators don't natively support visual edges,
        // We draw them manually as lines along each facet edge
        builder.setSymbology(ColorDef.black, ColorDef.black, 2);
        const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
        let flag = true;
        while (flag) {
          const numIndices = visitor.pointCount;
          for (let i = 0; i < numIndices - 1; i++) {
            const point1 = visitor.getPoint(i);
            const point2 = visitor.getPoint(i + 1);
            if (point1 && point2) {
              builder.addLineString([point1, point2]);
            }
          }
          flag = visitor.moveToNextFacet();
        }
      }
    } else if (geometry instanceof LineSegment3d) {
      const pointA = geometry.point0Ref;
      const pointB = geometry.point1Ref;
      const lineString = [pointA, pointB];
      builder.addLineString(lineString);
    } else if (geometry instanceof Arc3d) {
      builder.addArc(geometry, false, false);
    } else if (geometry instanceof CurveChainWithDistanceIndex) {
      this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
    }
  }

  // Generates new graphics if needed, and adds them to the scene
  public decorate(context: DecorateContext): void {
    const overrides = new ViewFlagOverrides();
    overrides.setShowVisibleEdges(true);
    overrides.setApplyLighting(true);
    const branch = new GraphicBranch(false);

    branch.viewFlagOverrides.copyFrom(overrides);

    context.viewFlags.visibleEdges = true;
    if (!this._graphics)
      this._graphics = this.createGraphics(context);

    if (this._graphics)
      branch.add(this._graphics);

    const graphic = context.createBranch(branch, Transform.identity);
    context.addDecoration(GraphicType.Scene, graphic);
  }
  
}