import { RealtimeUserLocationsDecorator } from "../decorators/RealtimeUserLocationsDecorator";
import { IModelApp } from "@bentley/imodeljs-frontend";
import { LatestUserLocation } from "../../client/UserLocationClient"

export default class RealtimeUserLocationsDecoratorApi {
    public static decorator?: RealtimeUserLocationsDecorator ;
    public static addedDecorator = false;
  
    public static setupDecorator(latestUserLocations: Array<LatestUserLocation>) {
        
        if (undefined === RealtimeUserLocationsDecoratorApi.decorator){
            RealtimeUserLocationsDecoratorApi.decorator = new RealtimeUserLocationsDecorator();
        }
        RealtimeUserLocationsDecoratorApi.decorator.setMarkersData(latestUserLocations);

        // console.log("RealtimeUserLocationsDecoratorApi.setupDecorator", RealtimeUserLocationsDecoratorApi.decorator)
        
    }

    // public static addDecoratorPoints(latestUserLocations: Array<LatestUserLocation>) {
    //     // console.log("RealtimeUserLocationsDecoratorApi.addDecoratorPoints", RealtimeUserLocationsDecoratorApi.decorator)
    //     if (undefined === RealtimeUserLocationsDecoratorApi.decorator)
    //         return;
    //     // console.log("RealtimeUserLocationsDecoratorApi.decorator.setMarkersData", currentUserLocations, bentleyLocations)
    //     RealtimeUserLocationsDecoratorApi.decorator.setMarkersData(latestUserLocations);
    // }

  //   public static addDecoratorPoint(latestUserLocation: LatestUserLocation) {
  //       if (undefined === RealtimeUserLocationsDecoratorApi.decorator)
  //           return;
  //       RealtimeUserLocationsDecoratorApi.decorator.addMarkerPoint(latestUserLocation);
  //   }

  //   public static updateDecoratorPoint(latestUserLocation: LatestUserLocation) {
  //     if (undefined === RealtimeUserLocationsDecoratorApi.decorator){
  //       return;
  //     }

  //     // RealtimeUserLocationsDecoratorApi.decorator.clearMarkers();
  //     RealtimeUserLocationsDecoratorApi.decorator.updateMarkerLocation(latestUserLocation);
  // }
  
    public static enableDecorations() {
      if (!RealtimeUserLocationsDecoratorApi.addedDecorator && RealtimeUserLocationsDecoratorApi.decorator) {
        // console.log("enableDecorations RealtimeUserLocationsDecoratorApi.decorator",RealtimeUserLocationsDecoratorApi.decorator)
        IModelApp.viewManager.addDecorator(RealtimeUserLocationsDecoratorApi.decorator);
        RealtimeUserLocationsDecoratorApi.addedDecorator = true;
      }
    }
  
    public static disableDecorations() {
      // console.log("disableDecorations RealtimeUserLocationsDecoratorApi.decorator",RealtimeUserLocationsDecoratorApi.decorator)
        
      if (undefined === RealtimeUserLocationsDecoratorApi.decorator)
        return;
  
      IModelApp.viewManager.dropDecorator(RealtimeUserLocationsDecoratorApi.decorator);
      RealtimeUserLocationsDecoratorApi.addedDecorator = false;
    }

    public static clearDecoratorPoints() {
        if (undefined === RealtimeUserLocationsDecoratorApi.decorator)
        return;

        RealtimeUserLocationsDecoratorApi.decorator.clearMarkers();
    }
  }