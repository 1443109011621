import { Landmark } from "../../client/LandmarksClient"
import { PoILandmarkDecorator } from "../decorators/PoILandmarkDecorator";
import { IModelApp } from "@bentley/imodeljs-frontend";

// import { request, Response } from "@bentley/itwin-client";
export class PoILandmarkDecoratorApi {
    public static setupDecorator() {
        return new PoILandmarkDecorator();
    }

    public static enableDecorations(decorator: PoILandmarkDecorator) {
        IModelApp.viewManager.addDecorator(decorator);
    }

    public static disableDecorations(decorator: PoILandmarkDecorator) {
        IModelApp.viewManager.dropDecorator(decorator);
    }

    public static clearDecoratorPoints(decorator: PoILandmarkDecorator) {
        decorator.clearMarkers();
    }

    public static addDecoratorPoint(decorator: PoILandmarkDecorator, landmark: Landmark, onMouseButtonCallback?: any) {
        decorator.addMarkerPoint(landmark, onMouseButtonCallback);
    }
}


