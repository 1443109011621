
import { IModelApp } from "@bentley/imodeljs-frontend";
import { Point3d, LineString3d } from "@bentley/geometry-core";
import { ColorDef } from "@bentley/imodeljs-common";

import { HeatmapLineDecorator } from "../decorators/HeatmapLineDecorator";
import {UserPath} from "../../client/UserPathClient"

interface PathInfo {
  path: Point3d[];
  frequency: number;
  frequencyPercentage: number;
}


export default class HeatmapLineDecoratorApi {
  public static decorator?: HeatmapLineDecorator ;
  public static addedDecorator = false;

  public static setupDecorator() {
    if(undefined === HeatmapLineDecoratorApi.decorator)
    HeatmapLineDecoratorApi.decorator = new HeatmapLineDecorator();
  }

  public static enableDecorations() {
    if (!HeatmapLineDecoratorApi.addedDecorator && HeatmapLineDecoratorApi.decorator) {
      IModelApp.viewManager.addDecorator(HeatmapLineDecoratorApi.decorator);
      HeatmapLineDecoratorApi.addedDecorator = true;
    }
  }

  public static disableDecorations() {
    if (undefined === HeatmapLineDecoratorApi.decorator)
      return;
    IModelApp.viewManager.dropDecorator(HeatmapLineDecoratorApi.decorator);
    HeatmapLineDecoratorApi.addedDecorator = false;
  }
  
  public static showPathLineList(bentleyLocations: {[key: string]: Array<number>}, userPaths: Array<UserPath>, minTime: string, maxTime: string): number{
    // console.log("showPathLineList", bentleyLocations, userPaths)
    if (undefined === HeatmapLineDecoratorApi.decorator){
      HeatmapLineDecoratorApi.decorator = new HeatmapLineDecorator();
    }
    // This function updates the decorator which draws the geometry in the view.  We call this when this
    // component is first mounted and then any time component's state is changed.
    HeatmapLineDecoratorApi.decorator.clearGeometry()
    const [pathInfos, maxOccurenceCount] = HeatmapLineDecoratorApi.getPathLineList(bentleyLocations, userPaths, minTime, maxTime)
    
    pathInfos.forEach((pathInfo) => {
      const color = HeatmapLineDecoratorApi.getHeatmapColor(pathInfo.frequencyPercentage)
      // console.log("path and color", color, pathInfo)
      HeatmapLineDecoratorApi.decorator?.addGeometry(LineString3d.create(pathInfo.path), ColorDef.fromString(color), ColorDef.fromString(color))
    });

    return maxOccurenceCount
  }

  public static getHeatmapColor(percentageValue: number){

    var h = (1.0 - Math.min(percentageValue,1.0)) * 240
    return "hsl(" + h + ", 100%, 50%)";
  }

  public static getPathLineList(bentleyLocations: {[key: string]: Array<number>}, userPaths: Array<UserPath>, minTime: string, maxTime: string):[Array<PathInfo>, number] {
    let pathPoints: Array<PathInfo> = [];

    let maxOccurenceCount: number = 0;
    let pathOccurenceCount: {[key: string]: number} = {};
    userPaths.forEach((userPath) => {
      if (maxTime && minTime){
        // console.log("aggregatePath time:",  minTime, maxTime)
        if (minTime > userPath.time || maxTime < userPath.time){
          // console.log("skip:",  userPath )
          return [pathPoints, maxOccurenceCount];
        }
      }
      // console.log("process:",  userPath )
      if ( pathOccurenceCount[userPath.landmarkIds] === undefined){
        pathOccurenceCount[userPath.landmarkIds] = userPath.occurrenceSum;
      }else{
        pathOccurenceCount[userPath.landmarkIds] += userPath.occurrenceSum;
      }
      if (pathOccurenceCount[userPath.landmarkIds] > maxOccurenceCount) {
        maxOccurenceCount = pathOccurenceCount[userPath.landmarkIds];
      }
    })
    // console.log("pathOccurenceCount", pathOccurenceCount)
    for (let key in pathOccurenceCount) {
      const landmarkIds = key.split("|");
      if (!bentleyLocations[landmarkIds[0]] || !bentleyLocations[landmarkIds[1]]){
        console.log("error path: ", key)
        continue
      }
      const pathInfo:PathInfo = ({
        path: [ Point3d.create(bentleyLocations[landmarkIds[0]][0],bentleyLocations[landmarkIds[0]][1]),
                Point3d.create(bentleyLocations[landmarkIds[1]][0],bentleyLocations[landmarkIds[1]][1])],
        frequency: pathOccurenceCount[key],
        frequencyPercentage: pathOccurenceCount[key]/ (maxOccurenceCount * 0.8)
      });
      pathPoints.push(pathInfo);
    }
    return [pathPoints, maxOccurenceCount]
  }
}