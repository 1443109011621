import {Status} from "./LocationStatus"

export interface BuildingInfo{
    buildingName: string;
    clientName: string;
    floorNumber: number;
    otherLandmarks: {[key: string]: Landmark};
    poiBuildingLandmarks: {[key: string]: Landmark};
}

export interface Landmark {
    /** Unique identifier for this instance. Read-only. */
    ID: string;
  
    /** Detailed description of the issue. */
    DestinationType?: number;

    /** Detailed description of the issue. */
    Name?: string;

    /** Detailed description of the issue. */
    ImageName?: string;

    // /** Detailed description of the issue. */
    // Image?:Blob;

    /** Detailed description of the issue. */
    Description?: string;

    /** Detailed description of the issue. */
    BarrierType:
    | "Boundary"
    | "QRCode";

    /** The display name of the user who originally created this issue. Read-only. */
    DownloadSpeed?: number;
  
    /** Name that should be used to show this issue in a list of issues in the UI. This is read-only. Project managers can configure how this is generated; usually, it will be the value of another property. */
    Connection?: Connection;

    /** The date and time when this issue was originally created. Read-only. */
    BentleyLocation: Array<number>;
  
    /** The date and time when this status is start. Read-only. */
    WorldLocation?: Array<number>;

    Status? : Array<Status>;

    CurrentStatus? : string;
  
  }

interface Connection{
    "to": Array<number>;
}


export class LandmarksClient {
    public static async getBuildingInfo(): Promise<BuildingInfo | undefined>  {
        // const request = "http://127.0.0.1:5000/api/v2/bentley/landmark/ix_construction/dc9a6fad-6d0f-4d08-a14d-3867f1f7634a"
        const request = "https://dev-ios-navigation-core-api.nearabl.com/api/v2/bentley/landmark/ix_construction/dc9a6fad-6d0f-4d08-a14d-3867f1f7634a"
        const response = await fetch(request, {
            method: 'GET', 
            mode: 'cors',  
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true'
              }
            });
        console.log("getBuildingInfo",response)
        let buildingInfo: BuildingInfo = await response.json(); //JSON.parse(response.data);
        console.log("getBuildingInfo",buildingInfo)
        return buildingInfo;
    }

    public static async getLandmarkLocations(): Promise<{[key: string]: Array<number>} | undefined>  {
        // const request = "http://127.0.0.1:5000/api/v2/bentley/landmark_locations/ix_construction/dc9a6fad-6d0f-4d08-a14d-3867f1f7634a"
        const request = "https://dev-ios-navigation-core-api.nearabl.com/api/v2/bentley/landmark_locations/ix_construction/dc9a6fad-6d0f-4d08-a14d-3867f1f7634a"
        const response = await fetch(request, {
            method: 'GET', 
            mode: 'cors',  
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true'
              }
            });
        console.log("getLandmarkLocations",response)
        let bentleyLocations: {[key: string]: Array<number>} = await response.json(); //JSON.parse(response.data);
        console.log("bentleyLocations",bentleyLocations)
        return bentleyLocations;
    }

    public static async addLandmarkStatus(currentLandmark: Landmark, statusInfo: Status): Promise<Status | undefined> {
        // const request = `http://127.0.0.1:5000/api/v2/status/landmark/ix_construction/Starwood Building/${currentLandmark.ID}`
        
        const request = `https://dev-ios-modeling-core-api.nearabl.com/api/v2/status/landmark/ix_construction/Starwood%20Building/${currentLandmark.ID}`
        const data = {
            landmark: {
                id: currentLandmark.ID,
                name: currentLandmark.Name,
                transform_world_location: currentLandmark.WorldLocation
            },
            status: statusInfo
        }
        console.log("addLandmarkStatus data",JSON.stringify(data))
        const response = await fetch(request, {
            method: 'POST', 
            mode: 'cors',  
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(data)
            });
        console.log("addLandmarkStatus response",response)
        let status: Status = await response.json(); //JSON.parse(response.data);
        console.log("addLandmarkStatus return status",status)
        return status
    }
}