import { IModelApp } from "@bentley/imodeljs-frontend";
import { UiItemsProvider, ToolbarUsage, ToolbarOrientation, CommonToolbarItem, StageUsage, ToolbarItemUtilities, StagePanelLocation, StagePanelSection, AbstractWidgetProps } from "@bentley/ui-abstract";
import { Visualization } from "../Visualization";
import { PoILandmarksListWidget } from "../components/widgets/PoILandmarksListWidget";
import * as React from "react"; 
// on top left - add to the button
// UiItemsProvider - https://www.itwinjs.org/learning/ui/abstract/uiitemsprovider/
export class PoILandmarksWidgetProvider implements UiItemsProvider {
  public readonly id = "PoILandmarksWidgetProvider"; //property id - mandatory 
  private _toggleWalls: boolean = false;

  // call once as soon as provider is register to the viewer
  public provideToolbarButtonItems(stageId: string, stageUsage: string, toolbarUsage: ToolbarUsage, toolbarOrientation: ToolbarOrientation) : CommonToolbarItem[] {
    const toolbarButtonItems: CommonToolbarItem[] = [];

    // need to check below item to let provider know where to add the button
    if (stageUsage === StageUsage.General && toolbarUsage === ToolbarUsage.ContentManipulation && toolbarOrientation === ToolbarOrientation.Vertical) {

      //ToolbarItemUtilities class contain function to create toolbar button - 5 mandatory parameter
      const toggleWallsButton = ToolbarItemUtilities.createActionButton(
        "ToggleWalls",  // id
        1000,           // item priority, order to place in the toolbar
        "icon-element", // icon - from bentley package icon-generic/icons - list of svg to choose. (name format: `icon-{svg name}`)
        "Toggle Walls Tool", //label - action of the button
        () => {  //execute
          this._toggleWalls = !this._toggleWalls;
          Visualization.hideComponents(IModelApp.viewManager.selectedView!, this._toggleWalls);
        }
      );
      toolbarButtonItems.push(toggleWallsButton);
    }
    
    return toolbarButtonItems;
  }

  //augment widget list
  public provideWidgets(stageId: string, stageUsage: string, location: StagePanelLocation, section?: StagePanelSection) : ReadonlyArray<AbstractWidgetProps> {
    const widgets: AbstractWidgetProps[] = [];
    // check of widget in collect location
    // stageId === "DefaultFrontstage" - viewer first use when open it
    if (stageId === "DefaultFrontstage" && location === StagePanelLocation.Right) {
      const widget: AbstractWidgetProps = {
        id: "smartDeviceListWidget",
        label: "Point of Interests",
        getWidgetContent: () => {  // only mandatory
          return <PoILandmarksListWidget></PoILandmarksListWidget>  // react component
        }
      }
      widgets.push(widget);
    }

    return widgets;

  }
}
